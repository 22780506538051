<template>
  <v-container fluid>
    <report-template-form class="pa-4" v-model="payload" @save="save" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ReportTemplateForm from "@/components/report/template/ReportTemplateForm.vue";

export default {
  name: "ReportTemplateCreate",

  components: {
    ReportTemplateForm,
  },

  data() {
    return {
      payload: {
        name: "",
        description: "",
        file: null,
        exportType: 0,
      },
    };
  },

  methods: {
    ...mapActions("reports", ["createTemplate", "uploadTemplateFile"]),
    ...mapActions("alert", ["success", "error"]),

    async save(values) {
      let templateResult = await this.createTemplate(this.payload);
      if (!templateResult) return;

      // Upload the file
      if (!values.file || templateResult?.reportSoTemplateId === undefined) {
        this.success(this.$t("report.template.updateSuccess"));
        return;
      }

      const uploadPayload = new FormData();
      uploadPayload.append("file", values.file);
      let fileResult = await this.uploadTemplateFile({
        reportSoTemplateId: templateResult?.reportSoTemplateId,
        file: uploadPayload,
      });

      if (!fileResult) {
        this.error(this.$t("report.template.uploadFileError"));
        return;
      }

      await this.$router.push("/report");
    },
  },

  created() {},
};
</script>
